body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
/* Start FramWork */
.s1 {
	font-size: 28px;
}
.s2 {
	font-size: 21.33px;
	font-weight: bold;
}
.s3 {
	font-size: 16px;
}
.s4 {
	font-size: 12px;
}

.primary_btn {
	border-radius: 5px;
	width: 250px;
	height: 52px;
}
.btn_with_icon {
	border-radius: 5px;
	width: 250px;
	height: 42px;
}
.primary-color {
	color: #946c20;
}
.aboutUsHeading{
	font-weight: bold;
}
 .borderLine {
	width: 113px;
	height: 20px;
	background-color: #f2d7a1;
	position: relative;
	bottom: 77px;
	margin-bottom: 25px;
	transition: all 0.4s ease-in-out;
  }
  
 .borderLine:hover {
	width: 135px !important;
	background-color: #cda656 !important;
  }
/* End FramWork */
