.hover {
	background-color: #fff !important;
	z-index: 55555555 !important;
	color: #040404 !important;
}

.nav {
	background-color: #040404 !important;
	z-index: 55555555 !important;
	color: rgba(255, 255, 255, 0.55) !important;
}

.navbar-nav {
	margin-left: auto;
}
.nav-link {
	padding-right: 1.5rem !important;
}
@media (max-width: 768px) {
	.navbar:hover .navbar-collapse {
		background: #f8f9fa;
	}
	.navbar-collapse {
		position: absolute;
		top: 60px;
		left: 0;
		z-index: 1500000;
		background: rgba(0, 0, 0, 0.8);
		width: 100%;
	}
	.navbar-collapse a {
		text-align: center;
		font-size: 18px;
	}
	.navbar-toggler {
		padding: 1px 13px !important;
	}
	.navbar-toggler-icon {
		width: 24px !important;
		height: 24px !important;
	}
}
